@import url('/src/styles/main.css');
@import url('/src/styles/home_mobile.css');

/*----------------------------------------------------------------------------*/
/*Intro*/
/*----------------------------------------------------------------------------*/
body main section.intro {
  padding: 120px 80px 200px 80px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  max-width: unset;
  background-color: var( --blue-1 );
}

body main section.intro > div.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  align-items: center;
  padding-top: 60px;
}

body main section.intro > div.container div.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

body main section.intro > div.container div.left > * {
  text-align: left;
}

body main section.intro > div.container div.left > h1 {
  font-weight: 400;
  text-align: center;
  color: #F8F8F8;
}

body main section.intro > div.container h1 span {
  color: var( --blue-primary );
  color: var( --orange-primary );
  font-weight: 400;
  font-style: italic;
}

body main section.intro > div.container h2 {
  color: var( --gray-text );
  font-weight: 300;
  margin-bottom: 0;
  font-size: 1.2rem;
  margin: 10px 0;
  max-width: 70%;
  text-align: center !important;
  color: #F8F8F8;
}

body main section.intro > div.container h2 span {
  color: var( --blue-primary );
  font-weight: 400;
  color: var( --blue-3 );
  font-style: italic;
}

body main section.intro > div.container div.cta {
  display: flex;
  margin-top: 10px;
  margin-bottom: auto;
}

body main section.intro > div.container div.cta .button {
  width: 210px;
  font-weight: 400;
}

body main section.intro > div.container div.cta .button:last-child {
  margin-left: 10px;
  background-color: #36447d;
  border-color: var( --blue-3 );
  background-color: var( --orange-primary );
  border-color: var( --orange-primary );
}

body main section.intro > div.container > img {
  width: 80%;
  box-shadow: var( --blue-3 ) 0px 20px 40px -10px, var( --blue-3 ) 0px 15px 30px -15px;
  display: block;
  border-radius: 10px;
}

/*----------------------------------------------------------------------------*/
/*intro-2*/
/*----------------------------------------------------------------------------*/
body main section.intro-2 {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: unset;
  background-color: #000c67;
  background-color: var( --blue-2 );
}

body main section.intro-2 div.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

body main section.intro-2 span.setup {
  border: 1px solid #add9e6;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: 300;
  background-color: var( --main-background );
}

body main section.intro-2 h3 {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0;
  color: var( --main-background );
}

body main section.intro-2 h3 span {
  color: var( --orange-primary );
  font-size: 1.4rem;
}

body main section.intro-2 p {
  color: #c0c6d9;
  font-weight: 400;
}

/*----------------------------------------------------------------------------*/
/*intro-3*/
/*----------------------------------------------------------------------------*/
body main section.intro-3 {
  padding: 180px 0px;
  display: flex;
  max-width: unset;
  background-color: var( --orange-primary );
  background-color: var( --gray-background );
}

body main section.intro-3 div.container {
  display: flex;
  align-items: center;
  padding: 0 80px;
  max-width: 1440px;
}

body main section.intro-3 div.container > * {
  flex: 1;
}

body main section.intro-3 div.container div.left {
  padding-right: 60px;
}


body main section.intro-3 div.container div.left h2 {
  text-align: left;
  font-family: unset;
  font-weight: 500;
}

body main section.intro-3 div.container div.right {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;
  flex: 2;
}

body main section.intro-3 div.container div.right > div {
  background-color: #d6dbf3;
  background-color: var( --main-background );
  padding: 30px;
  border-radius: 5px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

body main section.intro-3 div.container div.right > div img {
  margin-bottom: 15px;
  background-color: #f5f6f8;
  border: 1px solid var( --blue-4);
  border: 1px solid var( --orange-primary );
  padding: 10px;
  border-radius: 50%;
  width: 50px;
}
 
body main section.intro-3 div.container div.right > div h3 {
  font-size: .9rem;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 0;
  text-align: center;
}

body main section.intro-3 div.container div.right > div p {
  text-align: center;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0;
  font-size: .8rem;
}
/*----------------------------------------------------------------------------*/
/*Core-Feature*/
/*----------------------------------------------------------------------------,*/
body main section.core-feature {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  max-width: unset;
  align-items: center;
  padding-bottom: 0;
}

body main section.core-feature > div.container {
  display: flex;
  padding: 80px;
  max-width: 1440px;
}

body main section.core-feature div.container > div {
  flex: 1;
  padding: 30px;
}

body main section.core-feature div.container div.description {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  padding-right: 70px;
}

body main section.core-feature div.container.push div.description {
  padding-right: 0;
  padding-left: 70px;
}

body main section.core-feature div.container div.description h3 {
  text-align: left;
  font-weight: 600;
  font-size: 1.8rem;
}

body main section.core-feature div.container div.description > span {
  color: var( --gray-text );
}

body main section.core-feature div.container div.description > a {
  font-size: .8rem;
  margin-top: 20px;
  color: var(--violet-primary);
}

body main section.core-feature div.container div.description div.details {
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
  flex-direction: column;
}

body main section.core-feature div.container div.description div.details div.detail {
  display: flex;
  margin-right: 15px;
  align-items: center;
  border-radius: 5px;
  margin-top: 15px;
}

body main section.core-feature div.container div.description div.details div.detail img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

body main section.core-feature div.container div.description div.details div.detail p {
  font-size: .7rem;
  color: var( --gray-text );
  font-weight: 300;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-left: 30px;
}

body main section.core-feature div.container div.description div.details div.detail p span {
  font-weight: 600;
  margin-bottom: 3px;
}

body main section.core-feature div.container div.images {
  position: relative;
}

body main section.core-feature > div.container div.images img {
  border-radius: 10px;
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

body main section.core-feature > div.container div.images img {
  width: 100%;
}

body main section.core-feature > div.container div.images img:not(:last-child) {
  margin-bottom: 25px;
}

body main section.core-feature div.container.feed div.images img.feed {
  margin-left: 40px;
}

body main section.core-feature div.container.feed div.images div.bell {
  position: absolute;
  bottom: 120px;
  width: 70px;
  height: 70px;
  padding: 10px;
  background-color: #7d8cf9;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

body main section.core-feature div.container.feed div.images div.bell::after {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  content: '3';
  background-color: red;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: #fff;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: .6rem;
  justify-content: center;
}

body main section.core-feature div.container.feed div.images div.bell img {
  box-shadow: none;
}

body main section.core-feature div.container.push div.images img.onboarding {
  margin-left: auto;
  width: 360px;
  height: 150px;
}

body main section.core-feature div.container.push div.images img.settings {
  bottom: 0;
  right: 100px;
  height: 250px;
  width: 330px;
  position: absolute;
}

/*----------------------------------------------------------------------------*/
/*More features sub banner*/
/*----------------------------------------------------------------------------*/
body main section.see-more {
  background-color: var( --orange-primary );
  max-width: unset;
}

body main section.see-more div.container {
  max-width: 1440px;
  padding: 30px 80px;
  display: flex;
  align-items: center ;
}

body main section.see-more div.container h3 {
  margin: 0;
  margin-bottom: 0 !important;
  margin-right: 30px;
}

body main section.see-more div.container a {
  color: var( --blue-2 );
  font-weight: 600;
}

/*----------------------------------------------------------------------------*/
/*Demo*/
/*----------------------------------------------------------------------------*/
body main section.demo {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: var( --blue-1 );;
  max-width: unset;
}

body main section.demo > div.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

body main section.demo > div.container h3 {
  font-weight: 300;
  font-size: 1.6rem;
  margin-bottom: 0;
}

body main section.demo > div.container p {
  text-align: center;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 1rem;
  margin-bottom: 40px;
  max-width: 50%;
  color: var( --main-background );
}

body main section.demo > div.container video {
  width: calc( 100% - 100px );
  border-radius: 10px;
}

/*----------------------------------------------------------------------------*/
/*GET STARTED*/
/*----------------------------------------------------------------------------*/
body main section.get-started {
  background-color: var( --blue-2 );
  max-width: unset;
  color: white;
  color: #c0c6d9;
}

body main section.get-started div.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
} 

body main section.get-started div.container h2 {
  font-family: unset;
  color: var( --main-background );
  font-weight: 400;
}

body main section.get-started div.container div.steps {
  display: flex;
  margin-top: 50px;
}

body main section.get-started div.container div.steps div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  border: 1px solid var( --orange-primary );
  padding: 30px;
  border-radius: 5px;
  flex: 1;
}

body main section.get-started div.container div.steps div * {
  text-align: center;
}

body main section.get-started div.container div.steps div img {
  width: 50px;
  height: 50px;
  margin-bottom: 0;
  background-color: var( --orange-primary );
  padding: 10px;
  border-radius: 50%;
}

body main section.get-started div.container div.steps div p {
  font-weight: 200;
  margin-top: 10px;
  color: var( --main-background );
  margin-top: 15px;
}

body main section.get-started div.container div.steps div p span {
  font-weight: bold;
}

/*----------------------------------------------------------------------------*/
/*FEATURES*/
/*----------------------------------------------------------------------------*/
body main section.features {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  max-width: unset;
}

body main section.features div.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
}

body main section.features div.container h3 {
  font-weight: 300;
  font-size: 1.6rem;
}

body main section.features div.container > p {
  color: var( --gray-text );
  text-align: center;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 1rem;
}

body main section.features div.container div.features {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
}

body main section.features div.container div.features div.feature {
  width: calc( 25% - 30px );
  margin: 0 15px;
  background-color:  rgba(255, 255, 255, 0.6);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
}

body main section.features div.container div.features div.feature img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
}

body main section.features div.container div.features div.feature span:first-of-type {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  font-weight: 500;
}

body main section.features div.container div.features div.feature span:last-child {
  font-size: .8rem;
  color: var( --gray-text );
}

body main section.features div.container div.features div.feature:nth-child(-n+4) {
  margin-bottom: 30px;
}

body main section.features div.container > a {
  color: #7c8afa;
  font-weight: 400;
}

body main section.features div.container div.features div.feature.scheduling img {
  background-color: #d5e1fb;
}

body main section.features div.container div.features div.feature.categories img {
  background-color: #c8f3ee;
}

body main section.features div.container div.features div.feature.analytics img {
  background-color: #fed7df;
}

body main section.features div.container div.features div.feature.languages img {
  background-color: #fdf5e3;
}

body main section.features div.container div.features div.feature.environments img {
  background-color: #fedef5;
}

body main section.features div.container div.features div.feature.themeing img {
  background-color: #fde8d3;
}

body main section.features div.container div.features div.feature.presets img {
  background-color: #d0f0e5;
}

body main section.features div.container div.features div.feature.criterias img {
  background-color: #d9eaf6;
}

/*----------------------------------------------------------------------------*/
/*cta**/
/*----------------------------------------------------------------------------*/
body main section.cta {
  padding: 120px 0px;
  width: 100%;
  max-width: unset;
  background-color: var(--blue-4);
  background: linear-gradient(to top, #e1e9fa, #e1e9fa, var(--main-background));
  background: linear-gradient(to top, var(--blue-4), var(--blue-4), var(--main-background));
}

body main section.cta div.container {
  display: flex;
  padding-left: 0;
  align-items: center;
  height: 500px;
  justify-content: center;
  max-width: 1440px;
}

body main section.cta div.container div.text {
  max-width: 40%;
  padding: 60px 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-right: 50px;
}

body main section.cta div.container div.text h3 {
  font-weight: 300;
  font-size: 50px;
  margin-bottom: 0px;
  text-align: left;
}

body main section.cta div.container div.text p {
  margin-bottom: 0;
  line-height: 1.2rem;
}

body main section.cta div.container div.text div.buttons {
  display: flex;
  width: 100%;;
}

body main section.cta div.container div.text div.buttons a.button {
  margin-top: 0;
  height: 40px;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 5px;
  font-size: .8rem;
  text-align: center;
  margin-top: 20px;
  margin-left: 0;
  margin-right: 20px;
  flex: 1;
  border-color: var( --orange-primary );
  background-color: var( --orange-primary );
  color: var( --main-background );
}

body main section.cta div.container div.text span {
  font-size: .8rem;
  font-weight: 100;
  margin-top: 10px;
  display: block;
}

body main section.cta div.container img {
  width: 50%;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 6px 2px var( --blue-4);
  box-shadow: 0 2px 6px 2px var( --blue-4);
  -moz-box-shadow: 0 2px 6px 2px var( --blue-4);

}

body main section.cta div.container div.image-wrapper {
  position: relative;
  display: inline-block;
  width: 50%;
}

body main section.cta div.container div.image-wrapper img {
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 6px 2px var(--blue-4);
  box-shadow: 0 2px 6px 2px var(--blue-4);
  -moz-box-shadow: 0 2px 6px 2px var(--blue-4);
}

body main section.cta div.container div.image-wrapper::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 15%;
  bottom: -30%;
  width: 70%;
  height: 20%;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  filter: blur(10px);
}
