@media only screen and (max-width: 767px) {

  /* ------------------------------------ */
  /* Intro */
  /* ------------------------------------ */
  body main section.intro {
    padding: 100px 30px !important;
    min-height: 100vh !important;
    justify-content: center;
  }

  body main section.intro div.container {
    padding: 0px !important;
  }

  body main section.intro div.container h2 {
    font-size: 24px !important;
    max-width: unset !important;
  }

  /* ------------------------------------ */
  /* Intro-2 */
  /* ------------------------------------ */
  body main section.intro-2 h3 {
    font-size: 1.17em !important;
  }

  /* ------------------------------------ */
  /* Core Features */
  /* ------------------------------------ */
  body main section.core-feature > div.container {
    flex-direction: column;
  }

  body main section.core-feature > div.container div.description {
    padding: 0px !important;
    margin-right: 0px !important;
  }

  body main section.core-feature > div.container div.description .details {
    padding-right: 30px !important;
  }

  body main section.core-feature > div.container div.description .details .detail {
    margin-right: 0px !important;
  }

  body main section.core-feature > div.container div.description a {
    text-align: center !important;
  }

  body main section.core-feature > div.container div.images {
    margin-top: 30px;
    padding: 0px !important;
  }

  body main section.core-feature > div.container div.images img {
    margin: 0px !important;
  }

  body main section.core-feature > div.container div.images div.bell {
    bottom: -20px !important;
    left: -10px !important;
    width: 60px !important;
    height: 60px !important;
  }

  body main section.core-feature > div.container.push {
    flex-direction: column-reverse;
  }
  
  body main section.core-feature > div.container.push div.images {
    
  }

  body main section.core-feature > div.container.push div.images img {
    position: relative !important;
    right: unset !important;
  }

  body main section.core-feature > div.container.push div.images img:first-child {
    margin-bottom: 30px !important;
  }

  /* ------------------------------------ */
  /* Intro-3 */
  /* ------------------------------------ */
  body main section.intro-3 > div.container,
  body main section.intro-3 > div.container div.right {
    display: flex !important;
    flex-direction: column;
  }

  body main section.intro-3 > div.container div.right > div {
    margin-top: 30px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  /* ------------------------------------ */
  /* Demo */
  /* ------------------------------------ */
  body main section.demo div.container p {
    max-width: unset !important;
  }

  body main section.demo div.container video {
    width: 100% !important;
    height: auto !important;
  }

  /* ------------------------------------ */
  /* Get started */
  /* ------------------------------------ */ 
  body main section.get-started .steps {
    flex-direction: column !important;
  }

  body main section.get-started .steps div {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 30px !important;
  }

  /* ------------------------------------ */
  /* Features */
  /* ------------------------------------ */
  body main section.features div.features {
    flex-direction: column !important;
  }

  body main section.features div.features div.feature {
    margin-bottom: 30px !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  /* ------------------------------------ */
  /* CTA */
  /* ------------------------------------ */
  body main section.cta div.container {
    flex-direction: column !important;
    height: unset !important;
    align-items: center !important;
  }
  
  body main section.cta div.container div.text {
    max-width: unset !important;
    padding: 0px !important;
    margin: 0px !important;
    align-items: center !important;
  }

  body main section.cta div.container div.text * {
    text-align: center !important;
  }

  body main section.cta div.container div.text div.buttons {
    padding: 0px !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  body main section.cta div.container div.text div.buttons a.button {
    margin-right: 0px !important;
  }

  body main section.cta div.container div.image-wrapper {
    width: 100% !important;
    margin-top: 30px;
  }  
}