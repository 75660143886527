/* Mobile Styles */
@media only screen and (max-width: 767px) {
  body {
    font-size: 18px !important;
  }

  h1 {
    font-size: 34px !important;
  }

  h3 {
   
  }

  .button {
    height: 40px !important;
  }
  
  /* ------------------------------------ */
  /* Header */
  /* ------------------------------------ */
  body header {
    padding: 10px 30px !important;
  }

  body header nav a.logo img {
    width: 130px !important;
  }

  body header nav a:not( .logo) {
    display: none !important;
  }
  
  /*menu toggle*/
  *.toggle.hidden {
    display: none !important;
  }

  *.toggle:not( .hidden  ) {
    display: block !important;
  }

  /* ------------------------------------ */
  /* Mobile menu */
  /* ------------------------------------ */
  .mobile-menu:not( .hidden ) {
    height: calc( 100vh - 54px );
    top: 54px;
    width: 100%;
    position: fixed;
    padding: 100px 40px;
    z-index: 2;
    background-color: var(--blue-1);
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .mobile-menu a {
    margin: 20px 0 !important;
    font-size: 1rem;
  }

  .mobile-menu a:not( .button ) {
    color: var( --main-background );
  } 
  
  /* ------------------------------------ */
  /* Main */
  /* ------------------------------------ */
  body main > section {
    padding:  0px !important;
  }

  body main > section div.container {
    padding: 60px 30px !important;
    max-width: 100vw !important;
  }
  
  /* ------------------------------------ */
  /* Footer */
  /* ------------------------------------ */
  body footer {
    padding: 30px !important;
    height: auto !important;
  }

  body footer section div {
    padding: 0 30px !important;
  }

  body footer section div a {
    font-size: .5rem !important;
  }
  
  /* ------------------------------------ */
  /* Cookie banner */
  /* ------------------------------------ */
  body section.cookie-banner {
    width: 100% !important;
    max-width: 100% !important;
  }

  body section.cookie-banner div.bottom span.cookie-button {
    width: 120px !important;
  }

  body section.cookie-banner div.bottom > a.cookie-button {
    width: 200px !important;
  }
  
}