@import url('./main_mobile.css');

@font-face {
  font-family: 'Montserrat';
  src: url('/src/assets/fonts/montserrat/Montserrat.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/src/assets/fonts/nunito/Nunito-VariableFont_wght.ttf') format('truetype');
  font-display: swap;
}

* {
  box-sizing: border-box;
  user-select: none;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline-block;
  font-family: 'Nunito', 'Montserrat', 'system-ui', 'sans-serif';
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  color: #202B40;
  font-size: 22px;
  scroll-behavior: smooth;
  letter-spacing: 1px;
  background-color: var(--main-background);
}

body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

h1 {
  font-size: 60px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --main-background: #f9f9f9;
  --main-background: #fcfcfc;

  --blue-primary: #03A9F4;
  --blue-1: #0A192F;
  --blue-2: #404880;
  --blue-2: #6f2af5;
  --blue-3: #7d8cf9;
  --blue-4: #B5DBE8;
  --blue-5: #8592ec;

  --gray-text: #474747;
  --gray-background: #f2f2f2;

  --orange-primary: #ff9800;
  --violet-primary: #8c52ff;
}

.button {
  border-radius: 4px;
  border: 1px solid var(--blue-primary);
  padding: 7px 20px;
  color: #1d6af3;
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  z-index: 1;
  max-width: 300px;
  justify-content: center;
  pointer-events: auto;
  flex-shrink: 0;
  background-color: var(--blue-primary);
  color: var(--main-background);
  font-weight: 300;
  height: 50px;
}

.bold {
  font-weight: 600;
}

/*----------------------------------------------------------------------------*/
/*Useragent*/
/*----------------------------------------------------------------------------*/
body div.useragent {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

body div.useragent img {
  height: 70px;
  margin-bottom: 10px;
}

/*----------------------------------------------------------------------------*/
/*Header*/
/*----------------------------------------------------------------------------*/
body header {
  display: flex;
  width: 100%;
  padding: 10px 60px;
  z-index: 1;
  align-items: center;
  position: fixed;
  top: 0;
  justify-content: center;
  background-color: var(--main-background);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: top ease .5s;
  z-index: 2;
}

body header.hidden {
  top: -100%;
}

body header nav {
  width: 100%;
  display: flex;
  align-items: center;
}

nav a {
  font-size: .8rem;
  color: var(--blue-3);
  color: var(--violet-primary);
}

body header nav a:not( :last-child) {
  margin-right: 10px;
}

body header nav a.logo {
  margin-right: auto;
}

nav a.logo img {
  width: 170px;
}

nav a.button {
  margin-top: 0;
  height: 30px;
  font-weight: 400;
  border-color: var(--orange-primary);
  background-color: var(--orange-primary);
}

body header nav img.menu {
  display: none;
  cursor: pointer;
}

body header nav img.close {
  display: none;
  cursor: pointer;
}

/*----------------------------------------------------------------------------*/
/*Mobile menu*/
/*----------------------------------------------------------------------------*/
.mobile-menu.hidden {
  display: none;
}

/*----------------------------------------------------------------------------*/
/*Main*/
/*----------------------------------------------------------------------------*/
body main {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  flex: 1 0 auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  padding-bottom: 0;
}

body main > section {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 1;
  max-width: 1440px;
  padding: 8px 0;
}

body main>section .container {
  padding: 120px 80px;
}

/*----------------------------------------------------------------------------*/
/*FOOTER*/
/*----------------------------------------------------------------------------*/
body footer {
  padding: 60px;
  width: 100%;
  flex-shrink: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  background-color: rgb(13 28 81);
  background-color: var(--blue-1);
  color: #eef1f5;
}

body footer section {
  /*grid-template-columns: 1fr;*/
  margin-bottom: 30px;
  max-width: 1440px;
  display: flex;
  width: 100%;
  padding: 60px;
  margin-bottom: 0;
  border-radius: 50px;
  display: flex;
  justify-content: center;

}

body footer section div {
  display: flex;
  flex-direction: column;
  padding: 0 60px;
}

body footer section div h3 {
  font-weight: 300;
  margin-bottom: 20px;
  text-align: left;
  font-size: 1rem;
  color: var(--orange-primary);
  margin-bottom: 5px;
}

body footer section div a {
  font-size: .8rem;
  line-height: 1.2rem;
  font-weight: 100;
}

body footer section div a:hover {
  color: var( --blue-3 );
}

/*----------------------------------------------------------------------------*/
/*cookie-banner*/
/*----------------------------------------------------------------------------*/
body section.cookie-banner {
  position: fixed;
  bottom: 10px;
  left: 10px;
  transition: bottom ease .5s;
  background-color: var(--main-background);
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid var(--main-background);
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  z-index: 200;
  font-size: 17px;
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, .15);
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, .15);
  font-size: .7rem;
}

body section.cookie-banner.hidden {
  bottom: -100%;
}

body section.cookie-banner>span {
  margin-bottom: 15px;
  line-height: unset !important;
}

body section.cookie-banner a {
  color: #1d6af3;
}

body section.cookie-banner div.bottom {
  display: flex;
  align-items: center;
}

body section.cookie-banner div.bottom span.cookie-button {
  height: 40px;
  padding: 5px 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 150px;
  margin-right: 20px;
  color: #0a61f2;
  border: 1px solid #0a61f2;
}

body section.cookie-banner div.bottom > a.cookie-button {
  height: 40px;
  padding: 5px 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 250px;
  margin-right: 20px;
  border: 1px solid #202B40;
  color: #202B40;
  font-weight: 0;
}