@media only screen and (width <= 767px) {
  body {
    font-size: 18px !important;
  }

  h1 {
    font-size: 34px !important;
  }

  .button {
    height: 40px !important;
  }

  body header {
    padding: 10px 30px !important;
  }

  body header nav a.logo img {
    width: 130px !important;
  }

  body header nav a:not(.logo), .toggle.hidden {
    display: none !important;
  }

  .toggle:not(.hidden) {
    display: block !important;
  }

  .mobile-menu:not(.hidden) {
    z-index: 2;
    background-color: var(--blue-1);
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 54px);
    padding: 100px 40px;
    position: fixed;
    top: 54px;
    display: flex !important;
  }

  .mobile-menu a {
    font-size: 1rem;
    margin: 20px 0 !important;
  }

  .mobile-menu a:not(.button) {
    color: var(--main-background);
  }

  body main > section {
    padding: 0 !important;
  }

  body main > section div.container {
    max-width: 100vw !important;
    padding: 60px 30px !important;
  }

  body footer {
    height: auto !important;
    padding: 30px !important;
  }

  body footer section div {
    padding: 0 30px !important;
  }

  body footer section div a {
    font-size: .5rem !important;
  }

  body section.cookie-banner {
    width: 100% !important;
    max-width: 100% !important;
  }

  body section.cookie-banner div.bottom span.cookie-button {
    width: 120px !important;
  }

  body section.cookie-banner div.bottom > a.cookie-button {
    width: 200px !important;
  }
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat.c16ad322.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-VariableFont_wght.e400b0f6.ttf") format("truetype");
  font-display: swap;
}

* {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
}

html, body {
  -webkit-tap-highlight-color: #fff0;
  color: #202b40;
  scroll-behavior: smooth;
  letter-spacing: 1px;
  background-color: var(--main-background);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Nunito, Montserrat, "system-ui", "sans-serif";
  font-size: 22px;
  display: inline-block;
}

body {
  flex-direction: column;
  align-items: center;
  display: flex;
}

h1 {
  font-size: 60px;
}

h1, h2, h3, h4, h5 {
  text-align: center;
  margin: 0 0 15px;
  font-weight: bold;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --main-background: #fcfcfc;
  --blue-primary: #03a9f4;
  --blue-1: #0a192f;
  --blue-2: #6f2af5;
  --blue-3: #7d8cf9;
  --blue-4: #b5dbe8;
  --blue-5: #8592ec;
  --gray-text: #474747;
  --gray-background: #f2f2f2;
  --orange-primary: #ff9800;
  --violet-primary: #8c52ff;
}

.button {
  border: 1px solid var(--blue-primary);
  color: var(--main-background);
  cursor: pointer;
  z-index: 1;
  pointer-events: auto;
  background-color: var(--blue-primary);
  border-radius: 4px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 50px;
  margin-top: 20px;
  padding: 7px 20px;
  font-weight: 300;
  display: flex;
}

.bold {
  font-weight: 600;
}

body div.useragent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
}

body div.useragent img {
  height: 70px;
  margin-bottom: 10px;
}

body header {
  z-index: 1;
  background-color: var(--main-background);
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 60px;
  transition: top .5s;
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: 0 8px 24px #959da533;
}

body header.hidden {
  top: -100%;
}

body header nav {
  align-items: center;
  width: 100%;
  display: flex;
}

nav a {
  color: var(--violet-primary);
  font-size: .8rem;
}

body header nav a:not(:last-child) {
  margin-right: 10px;
}

body header nav a.logo {
  margin-right: auto;
}

nav a.logo img {
  width: 170px;
}

nav a.button {
  border-color: var(--orange-primary);
  background-color: var(--orange-primary);
  height: 30px;
  margin-top: 0;
  font-weight: 400;
}

body header nav img.menu, body header nav img.close {
  cursor: pointer;
  display: none;
}

.mobile-menu.hidden {
  display: none;
}

body main {
  z-index: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  padding-bottom: 0;
  display: flex;
  position: relative;
  overflow: hidden;
  flex: 1 0 auto !important;
}

body main > section {
  z-index: 1;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  padding: 8px 0;
  display: flex;
  position: relative;
}

body main > section .container {
  padding: 120px 80px;
}

body footer {
  z-index: 1;
  background-color: #0d1c51;
  background-color: var(--blue-1);
  color: #eef1f5;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  padding: 60px;
  display: flex;
}

body footer section {
  border-radius: 50px;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  margin-bottom: 0;
  padding: 60px;
  display: flex;
}

body footer section div {
  flex-direction: column;
  padding: 0 60px;
  display: flex;
}

body footer section div h3 {
  text-align: left;
  color: var(--orange-primary);
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 300;
}

body footer section div a {
  font-size: .8rem;
  font-weight: 100;
  line-height: 1.2rem;
}

body footer section div a:hover {
  color: var(--blue-3);
}

body section.cookie-banner {
  background-color: var(--main-background);
  border-top: 1px solid var(--main-background);
  appearance: none;
  z-index: 200;
  border: none;
  outline: none;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  padding: 20px;
  font-size: .7rem;
  transition: bottom .5s;
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 10px;
  box-shadow: 0 2px 6px 2px #00000026;
}

body section.cookie-banner.hidden {
  bottom: -100%;
}

body section.cookie-banner > span {
  margin-bottom: 15px;
  line-height: unset !important;
}

body section.cookie-banner a {
  color: #1d6af3;
}

body section.cookie-banner div.bottom {
  align-items: center;
  display: flex;
}

body section.cookie-banner div.bottom span.cookie-button {
  cursor: pointer;
  color: #0a61f2;
  border: 1px solid #0a61f2;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  margin-right: 20px;
  padding: 5px 30px;
  line-height: 40px;
  display: flex;
}

body section.cookie-banner div.bottom > a.cookie-button {
  cursor: pointer;
  color: #202b40;
  border: 1px solid #202b40;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 40px;
  margin-right: 20px;
  padding: 5px 30px;
  font-weight: 0;
  line-height: 40px;
  display: flex;
}

@media only screen and (width <= 767px) {
  body main section.intro {
    justify-content: center;
    min-height: 100vh !important;
    padding: 100px 30px !important;
  }

  body main section.intro div.container {
    padding: 0 !important;
  }

  body main section.intro div.container h2 {
    max-width: unset !important;
    font-size: 24px !important;
  }

  body main section.intro-2 h3 {
    font-size: 1.17em !important;
  }

  body main section.core-feature > div.container {
    flex-direction: column;
  }

  body main section.core-feature > div.container div.description {
    margin-right: 0 !important;
    padding: 0 !important;
  }

  body main section.core-feature > div.container div.description .details {
    padding-right: 30px !important;
  }

  body main section.core-feature > div.container div.description .details .detail {
    margin-right: 0 !important;
  }

  body main section.core-feature > div.container div.description a {
    text-align: center !important;
  }

  body main section.core-feature > div.container div.images {
    margin-top: 30px;
    padding: 0 !important;
  }

  body main section.core-feature > div.container div.images img {
    margin: 0 !important;
  }

  body main section.core-feature > div.container div.images div.bell {
    width: 60px !important;
    height: 60px !important;
    bottom: -20px !important;
    left: -10px !important;
  }

  body main section.core-feature > div.container.push {
    flex-direction: column-reverse;
  }

  body main section.core-feature > div.container.push div.images img {
    right: unset !important;
    position: relative !important;
  }

  body main section.core-feature > div.container.push div.images img:first-child {
    margin-bottom: 30px !important;
  }

  body main section.intro-3 > div.container, body main section.intro-3 > div.container div.right {
    flex-direction: column;
    display: flex !important;
  }

  body main section.intro-3 > div.container div.right > div {
    margin-top: 30px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  body main section.demo div.container p {
    max-width: unset !important;
  }

  body main section.demo div.container video {
    width: 100% !important;
    height: auto !important;
  }

  body main section.get-started .steps {
    flex-direction: column !important;
  }

  body main section.get-started .steps div {
    margin-bottom: 30px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  body main section.features div.features {
    flex-direction: column !important;
  }

  body main section.features div.features div.feature {
    width: 100% !important;
    margin-bottom: 30px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  body main section.cta div.container {
    height: unset !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  body main section.cta div.container div.text {
    max-width: unset !important;
    align-items: center !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  body main section.cta div.container div.text * {
    text-align: center !important;
  }

  body main section.cta div.container div.text div.buttons {
    flex-direction: column !important;
    align-items: center !important;
    padding: 0 !important;
  }

  body main section.cta div.container div.text div.buttons a.button {
    margin-right: 0 !important;
  }

  body main section.cta div.container div.image-wrapper {
    margin-top: 30px;
    width: 100% !important;
  }
}

body main section.intro {
  max-width: unset;
  background-color: var(--blue-1);
  flex-direction: column;
  align-items: center;
  padding: 120px 80px 200px;
  display: flex;
  position: relative;
  overflow: hidden;
}

body main section.intro > div.container {
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 0;
  display: flex;
}

body main section.intro > div.container div.left {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  display: flex;
}

body main section.intro > div.container div.left > * {
  text-align: left;
}

body main section.intro > div.container div.left > h1 {
  text-align: center;
  color: #f8f8f8;
  font-weight: 400;
}

body main section.intro > div.container h1 span {
  color: var(--orange-primary);
  font-style: italic;
  font-weight: 400;
}

body main section.intro > div.container h2 {
  color: #f8f8f8;
  max-width: 70%;
  margin: 10px 0;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center !important;
}

body main section.intro > div.container h2 span {
  color: var(--blue-3);
  font-style: italic;
  font-weight: 400;
}

body main section.intro > div.container div.cta {
  margin-top: 10px;
  margin-bottom: auto;
  display: flex;
}

body main section.intro > div.container div.cta .button {
  width: 210px;
  font-weight: 400;
}

body main section.intro > div.container div.cta .button:last-child {
  border-color: var(--blue-3);
  background-color: #36447d;
  background-color: var(--orange-primary);
  border-color: var(--orange-primary);
  margin-left: 10px;
}

body main section.intro > div.container > img {
  box-shadow: var(--blue-3) 0px 20px 40px -10px, var(--blue-3) 0px 15px 30px -15px;
  border-radius: 10px;
  width: 80%;
  display: block;
}

body main section.intro-2 {
  max-width: unset;
  background-color: #000c67;
  background-color: var(--blue-2);
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  display: flex;
}

body main section.intro-2 div.container {
  flex-direction: column;
  align-items: center;
  padding: 0;
  display: flex;
}

body main section.intro-2 span.setup {
  background-color: var(--main-background);
  border: 1px solid #add9e6;
  border-radius: 5px;
  margin-top: 20px;
  padding: 5px 10px;
  font-weight: 300;
}

body main section.intro-2 h3 {
  color: var(--main-background);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 300;
}

body main section.intro-2 h3 span {
  color: var(--orange-primary);
  font-size: 1.4rem;
}

body main section.intro-2 p {
  color: #c0c6d9;
  font-weight: 400;
}

body main section.intro-3 {
  max-width: unset;
  background-color: var(--orange-primary);
  background-color: var(--gray-background);
  padding: 180px 0;
  display: flex;
}

body main section.intro-3 div.container {
  align-items: center;
  max-width: 1440px;
  padding: 0 80px;
  display: flex;
}

body main section.intro-3 div.container > * {
  flex: 1;
}

body main section.intro-3 div.container div.left {
  padding-right: 60px;
}

body main section.intro-3 div.container div.left h2 {
  text-align: left;
  font-family: unset;
  font-weight: 500;
}

body main section.intro-3 div.container div.right {
  flex: 2;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;
  display: grid;
}

body main section.intro-3 div.container div.right > div {
  background-color: #d6dbf3;
  background-color: var(--main-background);
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 15px;
  padding: 30px;
  display: flex;
  box-shadow: 0 7px 29px #64646f33;
}

body main section.intro-3 div.container div.right > div img {
  border: 1px solid var(--blue-4);
  border: 1px solid var(--orange-primary);
  background-color: #f5f6f8;
  border-radius: 50%;
  width: 50px;
  margin-bottom: 15px;
  padding: 10px;
}

body main section.intro-3 div.container div.right > div h3 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: .9rem;
  font-weight: 400;
}

body main section.intro-3 div.container div.right > div p {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: .8rem;
  font-weight: 300;
}

body main section.core-feature {
  max-width: unset;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 0;
  display: flex;
}

body main section.core-feature > div.container {
  max-width: 1440px;
  padding: 80px;
  display: flex;
}

body main section.core-feature div.container > div {
  flex: 1;
  padding: 30px;
}

body main section.core-feature div.container div.description {
  flex-direction: column;
  margin-right: 50px;
  padding-right: 70px;
  display: flex;
}

body main section.core-feature div.container.push div.description {
  padding-left: 70px;
  padding-right: 0;
}

body main section.core-feature div.container div.description h3 {
  text-align: left;
  font-size: 1.8rem;
  font-weight: 600;
}

body main section.core-feature div.container div.description > span {
  color: var(--gray-text);
}

body main section.core-feature div.container div.description > a {
  color: var(--violet-primary);
  margin-top: 20px;
  font-size: .8rem;
}

body main section.core-feature div.container div.description div.details {
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
}

body main section.core-feature div.container div.description div.details div.detail {
  border-radius: 5px;
  align-items: center;
  margin-top: 15px;
  margin-right: 15px;
  display: flex;
}

body main section.core-feature div.container div.description div.details div.detail img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

body main section.core-feature div.container div.description div.details div.detail p {
  color: var(--gray-text);
  flex-direction: column;
  margin: 0 0 0 30px;
  font-size: .7rem;
  font-weight: 300;
  display: flex;
}

body main section.core-feature div.container div.description div.details div.detail p span {
  margin-bottom: 3px;
  font-weight: 600;
}

body main section.core-feature div.container div.images {
  position: relative;
}

body main section.core-feature > div.container div.images img {
  border-radius: 10px;
  width: 100%;
  display: flex;
  box-shadow: 0 7px 29px #64646f33;
}

body main section.core-feature > div.container div.images img:not(:last-child) {
  margin-bottom: 25px;
}

body main section.core-feature div.container.feed div.images img.feed {
  margin-left: 40px;
}

body main section.core-feature div.container.feed div.images div.bell {
  background-color: #7d8cf9;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 10px;
  position: absolute;
  bottom: 120px;
  box-shadow: 0 7px 29px #64646f33;
}

body main section.core-feature div.container.feed div.images div.bell:after {
  content: "3";
  color: #fff;
  background-color: red;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  padding: 3px;
  font-size: .6rem;
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
}

body main section.core-feature div.container.feed div.images div.bell img {
  box-shadow: none;
}

body main section.core-feature div.container.push div.images img.onboarding {
  width: 360px;
  height: 150px;
  margin-left: auto;
}

body main section.core-feature div.container.push div.images img.settings {
  width: 330px;
  height: 250px;
  position: absolute;
  bottom: 0;
  right: 100px;
}

body main section.see-more {
  background-color: var(--orange-primary);
  max-width: unset;
}

body main section.see-more div.container {
  align-items: center;
  max-width: 1440px;
  padding: 30px 80px;
  display: flex;
}

body main section.see-more div.container h3 {
  margin: 0 30px 0 0;
  margin-bottom: 0 !important;
}

body main section.see-more div.container a {
  color: var(--blue-2);
  font-weight: 600;
}

body main section.demo {
  background-color: var(--blue-1);
  max-width: unset;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

body main section.demo > div.container {
  z-index: 1;
  flex-direction: column;
  align-items: center;
  display: flex;
}

body main section.demo > div.container h3 {
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 300;
}

body main section.demo > div.container p {
  text-align: center;
  color: var(--main-background);
  max-width: 50%;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 300;
}

body main section.demo > div.container video {
  border-radius: 10px;
  width: calc(100% - 100px);
}

body main section.get-started {
  background-color: var(--blue-2);
  max-width: unset;
  color: #c0c6d9;
}

body main section.get-started div.container {
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  display: flex;
}

body main section.get-started div.container h2 {
  color: var(--main-background);
  font-family: unset;
  font-weight: 400;
}

body main section.get-started div.container div.steps {
  margin-top: 50px;
  display: flex;
}

body main section.get-started div.container div.steps div {
  border: 1px solid var(--orange-primary);
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 0 30px;
  padding: 30px;
  display: flex;
}

body main section.get-started div.container div.steps div * {
  text-align: center;
}

body main section.get-started div.container div.steps div img {
  background-color: var(--orange-primary);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-bottom: 0;
  padding: 10px;
}

body main section.get-started div.container div.steps div p {
  color: var(--main-background);
  margin-top: 15px;
  font-weight: 200;
}

body main section.get-started div.container div.steps div p span {
  font-weight: bold;
}

body main section.features {
  max-width: unset;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

body main section.features div.container {
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  display: flex;
}

body main section.features div.container h3 {
  font-size: 1.6rem;
  font-weight: 300;
}

body main section.features div.container > p {
  color: var(--gray-text);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 300;
}

body main section.features div.container div.features {
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
}

body main section.features div.container div.features div.feature {
  background-color: #fff9;
  border-radius: 5px;
  width: calc(25% - 30px);
  margin: 0 15px;
  padding: 20px;
  position: relative;
  box-shadow: 0 7px 29px #64646f33;
}

body main section.features div.container div.features div.feature img {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  padding: 5px;
}

body main section.features div.container div.features div.feature span:first-of-type {
  margin-bottom: 5px;
  font-weight: 500;
  display: block;
}

body main section.features div.container div.features div.feature span:last-child {
  color: var(--gray-text);
  font-size: .8rem;
}

body main section.features div.container div.features div.feature:nth-child(-n+4) {
  margin-bottom: 30px;
}

body main section.features div.container > a {
  color: #7c8afa;
  font-weight: 400;
}

body main section.features div.container div.features div.feature.scheduling img {
  background-color: #d5e1fb;
}

body main section.features div.container div.features div.feature.categories img {
  background-color: #c8f3ee;
}

body main section.features div.container div.features div.feature.analytics img {
  background-color: #fed7df;
}

body main section.features div.container div.features div.feature.languages img {
  background-color: #fdf5e3;
}

body main section.features div.container div.features div.feature.environments img {
  background-color: #fedef5;
}

body main section.features div.container div.features div.feature.themeing img {
  background-color: #fde8d3;
}

body main section.features div.container div.features div.feature.presets img {
  background-color: #d0f0e5;
}

body main section.features div.container div.features div.feature.criterias img {
  background-color: #d9eaf6;
}

body main section.cta {
  max-width: unset;
  background-color: var(--blue-4);
  background: linear-gradient(to top, #e1e9fa, #e1e9fa, var(--main-background));
  background: linear-gradient(to top, var(--blue-4), var(--blue-4), var(--main-background));
  width: 100%;
  padding: 120px 0;
}

body main section.cta div.container {
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  height: 500px;
  padding-left: 0;
  display: flex;
}

body main section.cta div.container div.text {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 40%;
  margin-right: 50px;
  padding: 60px 30px;
  display: flex;
}

body main section.cta div.container div.text h3 {
  text-align: left;
  margin-bottom: 0;
  font-size: 50px;
  font-weight: 300;
}

body main section.cta div.container div.text p {
  margin-bottom: 0;
  line-height: 1.2rem;
}

body main section.cta div.container div.text div.buttons {
  width: 100%;
  display: flex;
}

body main section.cta div.container div.text div.buttons a.button {
  text-align: center;
  border-color: var(--orange-primary);
  background-color: var(--orange-primary);
  color: var(--main-background);
  border-radius: 5px;
  flex: 1;
  height: 40px;
  margin-top: 20px;
  margin-left: 0;
  margin-right: 20px;
  padding: 0 20px;
  font-size: .8rem;
  font-weight: 300;
  line-height: 40px;
}

body main section.cta div.container div.text span {
  margin-top: 10px;
  font-size: .8rem;
  font-weight: 100;
  display: block;
}

body main section.cta div.container img {
  -webkit-box-shadow: 0 2px 6px 2px var(--blue-4);
  box-shadow: 0 2px 6px 2px var(--blue-4);
  -moz-box-shadow: 0 2px 6px 2px var(--blue-4);
  border-radius: 5px;
  width: 50%;
}

body main section.cta div.container div.image-wrapper {
  width: 50%;
  display: inline-block;
  position: relative;
}

body main section.cta div.container div.image-wrapper img {
  -webkit-box-shadow: 0 2px 6px 2px var(--blue-4);
  box-shadow: 0 2px 6px 2px var(--blue-4);
  -moz-box-shadow: 0 2px 6px 2px var(--blue-4);
  border-radius: 5px;
  width: 100%;
}

body main section.cta div.container div.image-wrapper:after {
  content: "";
  z-index: -1;
  filter: blur(10px);
  background: #00000026;
  border-radius: 50%;
  width: 70%;
  height: 20%;
  position: absolute;
  bottom: -30%;
  left: 15%;
}
/*# sourceMappingURL=index.e8898f96.css.map */
